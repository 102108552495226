import * as React from "react";
import { siteMetadata } from "../../gatsby-config.js";
import {
	Badge,
	Box,
	ChakraProvider,
	Container,
	Heading,
	Progress,
	Text,
} from "@chakra-ui/react";
import moment from "moment";

import theme from "../../theme";

const HomePage = () => {

	// Setup today's date
	let today = new Date();

	// Get today's name
	let todayName = today.toLocaleString("en-us", {
		weekday: "long",
	});

	// Set up this week
	const currentWeek = moment().format("W");

	// Calculate week progress (percent of year)
	const weekProgress = Math.round(currentWeek / 52 * 100);

	return (
		<>
			<ChakraProvider theme={theme}>
				<Container maxW="container.xs" py={8}>
					<title>{siteMetadata.title}</title>

					<Box textAlign="center">
						<Heading as="h1" mb={0}>
							{siteMetadata.title}
						</Heading>
						<Text mb={4}>It's {todayName}, legend.</Text>
					</Box>

					<Box mb={8}>
						<Badge mb={2}>Week {currentWeek}/52</Badge>
						<Progress value={weekProgress} />
					</Box>


				</Container>
			</ChakraProvider>
		</>
	);
};

export default HomePage;
